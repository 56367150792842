import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { useRouter } from 'next/router';

import NotFoundImage from 'oldAssets/404/bg-image-chiken.webp';
import { alpha } from '@mui/material';

const NotFoundPage = () => {
  const router = useRouter();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width={1}
      maxWidth={'dtlg'}
      height={1}
      maxHeight={{ phxs: 560, tb: 800 }}
      gap={1}
      position={'relative'}
      mb={4}
      overflow={'hidden'}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        width={{ phxs: 362, tb: 868 }}
        height={{ phxs: 600, tb: 1043 }}
        position={'relative'}
        bottom={0}
        sx={{ pointerEvents: 'none' }}
      >
        <Image
          unoptimized
          loading='eager'
          key={`not-found-image`}
          alt={`not-found-image`}
          src={NotFoundImage}
          style={{ objectFit: 'cover', height: '100%', width: 'auto' }}
        />
      </Box>

      <Box
        display='flex'
        gap={3}
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        border={'1px solid'}
        borderColor={'grey.700'}
        bgcolor={(theme) => alpha(theme.palette.grey[800], 0.7)}
        p={{ phxs: 3, tbxs: 6 }}
        borderRadius={6}
        width={{ phxs: 320, tbxs: 500 }}
        zIndex={1}
        position={'absolute'}
        bottom={0}
      >
        <Box
          display='flex'
          gap={2}
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <Typography component='h1' sx={{ typography: { phxs: 'h5', tbxs: 'h3' } }}>
            Page Not Found
          </Typography>

          <Typography
            sx={(theme) => ({
              typography: {
                phxs: 'body2',
                tbxs: 'subtitle2'
              },
              color: `${theme.palette.grey[400]} !important`
            })}
            textAlign='center'
            lineHeight={1.3}
          >
            Looks like this page doesn’t exist.
          </Typography>
        </Box>

        <Button variant='contained' color='primary' onClick={() => router.replace('/')}>
          Return to home
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
